import { css } from '@ptt-eia-web/helpers'
import {
  IEmployeeDataNoJobGroupResponse,
  IPTTPersonalInfoResponse,
  useGetEmployeeDataNoJobGroupMTT,
  useGetPTTPersonalInfoMTT,
} from '@ptt-eia-web/services'
import { Card, Col, Form, FormInstance, Row, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import { useCallback, useEffect } from 'react'
import { FaSearch } from 'react-icons/fa'

import { AsyncSelect } from '@frontend/components/AsyncSelect'

export type ISearchByKey = 'employeeId' | 'employeeNameTH'
interface ISearchFormValue {
  searchBy: string
  searchTxt: string
}
interface ISearchUserProps {
  form: FormInstance

  onSearch: (employee?: IEmployeeDataNoJobGroupResponse, personalInfo?: IPTTPersonalInfoResponse) => void
  onClear: () => void
}

export const SearchUser = ({
  form,

  onSearch,
  onClear,
}: ISearchUserProps) => {
  const { mutateAsync: getEmployeeData } = useGetEmployeeDataNoJobGroupMTT()
  const { mutateAsync: getPersonalInfo } = useGetPTTPersonalInfoMTT()

  const searchBy: ISearchByKey = useWatch('searchBy', form)
  const searchTxt: ISearchByKey = useWatch('searchTxt', form)

  const handleChangeSearchBy = useCallback(
    (value: ISearchByKey) => {
      form.setFields([{ name: 'searchTxt', value: '' }])
      onClear()
    },
    [form, onClear],
  )

  const handleSearch = useCallback(
    async (value: string) => {
      if (!searchBy) return []

      const data = await getEmployeeData({
        EmployeeID: searchBy === 'employeeId' ? value : undefined,
        EmployeeName: searchBy === 'employeeNameTH' ? value : undefined,
        Limit: 10,
        DepartmentCode: 80001404,
      })

      return data.map((d) => ({
        label: d[searchBy],
        value: d.employeeId,
      }))
    },
    [getEmployeeData, searchBy],
  )

  const handleClear = useCallback(() => {
    form.resetFields()
    onClear()
  }, [form, onClear])

  const handleClickSearch = useCallback(
    async (values: ISearchFormValue) => {
      if (!values.searchTxt || !values.searchBy) return
      try {
        const res = await getEmployeeData({
          Limit: 10,
          EmployeeID: values.searchTxt,
          DepartmentCode: 80001404,
        })
        const info = await getPersonalInfo({ Search_EmployeeCode: res[0].employeeId })
        onSearch(res[0], info[0])
      } catch {
        // no action
      }
    },
    [getEmployeeData, getPersonalInfo, onSearch],
  )

  useEffect(() => {
    if (searchTxt) {
      form.submit()
    } else {
      onClear()
    }
  }, [form, handleChangeSearchBy, onClear, searchBy, searchTxt])

  return (
    <Card
      css={css`
        .ant-card-body {
          padding: 16px;
        }
      `}
    >
      <Form layout="vertical" form={form} onFinish={handleClickSearch}>
        <Row gutter={[20, 0]}>
          <Col xs={24} md={12}>
            <FormItem label="Search By" name="searchBy" rules={[{ required: true }]}>
              <Select
                data-testid="select-search-by"
                options={[
                  { label: 'รหัสพนักงาน', value: 'employeeId' },
                  { label: 'ชื่อพนักงาน', value: 'employeeNameTH' },
                ]}
                onChange={handleChangeSearchBy}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem label="Search" name="searchTxt" rules={[{ required: true }]}>
              <AsyncSelect fetchFn={handleSearch} disabled={!searchBy} suffixIcon={<FaSearch />} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}
