import styled from '@emotion/styled'
import { css, numberFormat } from '@ptt-eia-web/helpers'
import {
  IProjectDashboardDtOutDto,
  IProjectDashboardOutDto,
  useGetDashboardSettingQRY,
  useGetProjectTypeDetailQRY,
} from '@ptt-eia-web/services'
import { Table } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { SPAN_HEADER_CLASS, colors } from '../../../app/theme'
import CheckSvg from '../../../assets/svgs/check-icon.svg?react'
import CloseSvg from '../../../assets/svgs/close-icon.svg?react'

import { CellEiaHighlightActivities } from './CellEiaHighlightActivities'
import {
  renderColSpanEIAActiveSwitch,
  renderColSpanMonitoringActive,
  renderColSpanMonitoringActiveWithoutLTA,
} from './ColSpanTable'

import type { ColumnsType } from 'antd/es/table'
const WITHOUT_LTA = 'Without LTA'

export interface DataType {
  id?: number
  key: string
  seqNo?: number
  projectId: number
  dtOutId: number | undefined
  projectName?: string | undefined
  mddashboardStatusId?: number | null
  progressPlan?: number | undefined
  progressActual?: number | undefined
  highlightActivity: string | undefined
  wayForward: string | undefined
  phase: string | undefined
  phaseNote: string | undefined
  totalMan: number | undefined
  targetMan: number | undefined
  lta: number | string | undefined
  eiaactive?: number | undefined
  eiaactiveReason?: string | undefined
  monitorActive?: number | undefined
  monitorReason?: string | undefined
  trir: number | undefined
  eiaCompliance: string | undefined
  sixMonth: string | undefined
  complianceHighlight: string | undefined
  projectRowSpan?: number | undefined
  eiaColSpan?: number | undefined
  dtOutRowSpan?: number | undefined
  planActualColors?: string | undefined
}

const CloseIcon = styled(CloseSvg)`
  text-align: center;
  align-self: center;
  width: 22px;
  height: 22px;
`
const createRow = (
  project: {
    id: number
    projectName: string
    mddashboardStatusId: number
  },
  dtOut?: {
    id: number
    progressPlan?: number | undefined
    progressActual?: number | undefined
    highlightActivity?: string | undefined
    wayForward?: string | undefined
    sixMonth?: string | undefined
    complianceHighlight?: string | undefined
    eiacompliance?: string | undefined
    mdreportStatusId?: number | null
    eiaactive?: number | undefined
    eiaactiveReason?: string | undefined
    monitorActive?: number | undefined
    monitorReason?: string | undefined
    progressActualColor?: string | undefined
  },
  detail?: {
    id?: number
    phase?: string | undefined
    phaseNote?: string | undefined
    curentYearMan?: number | undefined
    totalMan?: number | undefined
    targetMan?: number | undefined
    lta?: number | string | undefined
    trir?: number | undefined
  },
  isLTA?: boolean,
) => {
  return {
    projectId: project.id,
    projectName: project.projectName,
    mddashboardStatusId: dtOut?.mdreportStatusId,

    dtOutId: dtOut?.id,
    progressPlan: dtOut?.progressPlan,
    progressActual: dtOut?.progressActual,
    planActualColors: dtOut?.progressActualColor,
    highlightActivity: dtOut?.highlightActivity,
    wayForward: dtOut?.wayForward,
    eiaCompliance: dtOut?.eiacompliance,
    sixMonth: dtOut?.sixMonth,
    complianceHighlight: dtOut?.complianceHighlight,
    eiaactive: dtOut?.eiaactive,
    eiaactiveReason: dtOut?.eiaactiveReason,
    monitorActive: dtOut?.monitorActive,
    monitorReason: dtOut?.monitorReason,

    id: detail?.id,
    phase: detail?.phase,
    phaseNote: detail?.phaseNote,
    curentYearMan: detail?.curentYearMan,
    totalMan: detail?.totalMan,
    targetMan: detail?.targetMan,
    lta: `${isLTA ? WITHOUT_LTA : detail?.lta ?? ''}`,
    // lta: detail?.lta,
    trir: detail?.trir,
  }
}

const createRows = (dtOut: IProjectDashboardDtOutDto) => {
  const combinedData = []

  if (dtOut.projectDashboardSHEOutDtos) {
    combinedData.push(...dtOut.projectDashboardSHEOutDtos)
  }
  if (dtOut.projectDashboardLTAOutDtos) {
    dtOut.projectDashboardLTAOutDtos.forEach((lta) => {
      combinedData.push(lta)
    })
  }

  const firstItem = combinedData.filter((item) => item.id === 0)
  const sortedData = combinedData.filter((item) => item.id !== 0).sort((a, b) => a.phase.localeCompare(b.phase))
  sortedData.unshift(...firstItem)
  return sortedData
}

const checkLTATable = (lta: number, trir: number) => {
  let isLTA = false
  if (lta === undefined || trir === undefined) {
    isLTA = true
  }
  return isLTA
}

const getFlatData = (data: IProjectDashboardOutDto[]) => {
  const flatData = []
  let newKey = 0
  let previousProjectId: number | null = null

  for (const project of data) {
    const { projectDashboardDtOutDtos } = project

    if (!projectDashboardDtOutDtos?.length) {
      flatData.push(createRow(project))
      continue
    }
    for (const dtOut of projectDashboardDtOutDtos) {
      if (!(dtOut.projectDashboardSHEOutDtos?.length || dtOut.projectDashboardLTAOutDtos?.length)) {
        flatData.push(createRow(project, dtOut))
        continue
      }
      let isLTA = false
      const sortedData = createRows(dtOut)
      flatData.push(
        ...sortedData.map((item) => {
          isLTA = checkLTATable(item?.lta, item?.trir)
          return createRow(project, dtOut, item, isLTA)
        }),
      )
    }
  }

  const result = flatData.map((item) => {
    const projectId = item.projectId

    if (projectId !== previousProjectId) {
      newKey = 0
      previousProjectId = projectId
    }

    newKey++

    return { ...item, key: newKey.toString() }
  })

  return result
}

const calRowSpan = (data: DataType[]) => {
  const hash: AnyObject = {}
  for (const row of data) {
    hash[row.projectId] ??= { span: 0 }
    hash[row.projectId].span++

    hash[row.projectId][row.dtOutId ?? 0] ??= { span: 0 }
    hash[row.projectId][row.dtOutId ?? 0].span++
  }
  return hash
}

const mapCellSpanToRow = (data: DataType[]): DataType[] => {
  const hash = calRowSpan(data)
  for (const row of data) {
    row.projectRowSpan = hash[row.projectId].span
    hash[row.projectId].span = 0

    row.dtOutRowSpan = hash[row.projectId][row.dtOutId ?? 0].span
    hash[row.projectId][row.dtOutId ?? 0].span = 0
  }
  let seqNo = 0
  return data.map((item) => {
    if (item.projectRowSpan && item.projectRowSpan > 0) {
      seqNo += 1
      return { ...item, seqNo }
    } else {
      return { ...item }
    }
  })
}

const columnTitleStyles = css`
  font-weight: 900;
  font-size: 12px;
  text-align: center;
`
const CheckIcon = styled(CheckSvg)`
  text-align: center;
  align-self: center;
  #Ellipse_1973 {
    fill: ${colors.primary};
  }
  #Ellipse_1974 {
    fill: ${colors.primary};
  }
  #Path_16408 {
    fill: ${colors.primary};
  }
  #Path_16407 {
    fill: ${colors.white};
  }
  #Path_16406 {
    fill: ${colors.primary};
  }
  #Path_16408 {
    fill: ${colors.primary};
  }
`

const columnTitleWhiteTextStyles = css`
  font-weight: 900;
  font-size: 12px;
  text-align: center;
  align-self: center;
  color: ${colors.white};
`
const columnTextStyles = css`
  text-align: center;
  align-self: center;
  font-size: 12px;
`

const FixedWidthTitle = (props: { title: string; width: string }) => {
  return (
    <p
      css={css`
        ${columnTitleStyles}
        min-width: ${props.width};
      `}
    >
      {props.title}
    </p>
  )
}

export interface IFullTableProps {
  dataSource: IProjectDashboardOutDto[] | undefined
  typeTable: string
}

export const FullTableData = (props: IFullTableProps) => {
  const [trirTitleValue, setTrirTitleValue] = useState('0')
  const [ltaTitleValue, setLtaTitleValue] = useState('0')
  const { data: projectType } = useGetProjectTypeDetailQRY(3)
  const { data: maserSetting } = useGetDashboardSettingQRY()
  useEffect(() => {
    if (maserSetting) {
      setTrirTitleValue(String(maserSetting.trir))
      setLtaTitleValue(String(maserSetting.lta))
    }
  }, [maserSetting])
  const currentYear = new Date().getFullYear()

  const renderFontBold = useCallback(
    (record: DataType) => {
      if (record.key === '1' && record.monitorActive === 1 && props.typeTable !== projectType?.mdprojectTypeOut?.name) {
        return 'bold'
      }
      return 'normal'
    },
    [props.typeTable, projectType?.mdprojectTypeOut],
  )

  const renderColorBlack = useCallback(
    (record: DataType) => {
      if (record.key === '1' && record.monitorActive === 1 && props.typeTable !== projectType?.mdprojectTypeOut?.name) {
        return 'black'
      }
      return ''
    },
    [props.typeTable, projectType?.mdprojectTypeOut],
  )

  const setColorReportStatus = useCallback((record: DataType, indexReportStatus: number) => {
    if (record.eiaactive === 1 && record.mddashboardStatusId === indexReportStatus) {
      return '#315496'
    }
    return 'transparent'
  }, [])

  const preparedDataSource = useMemo(() => mapCellSpanToRow(getFlatData(props.dataSource ?? [])), [props.dataSource])
  const columns: ColumnsType<DataType> = useMemo(
    () => [
      {
        title: () => {
          return <p css={columnTitleStyles}>NO.</p>
        },
        dataIndex: 'seqNo',
        onHeaderCell: () => ({
          className: SPAN_HEADER_CLASS,
        }),
        width: '10px',
        onCell: (record) => ({
          rowSpan: record.projectRowSpan,
        }),
      },
      {
        title: <FixedWidthTitle width="200px" title="PROJECT" />,
        dataIndex: 'projectName',
        onHeaderCell: () => ({
          className: SPAN_HEADER_CLASS,
        }),
        onCell: (record) => ({
          rowSpan: record.projectRowSpan,
        }),
        render: (text) => {
          return (
            <div
              css={css`
                white-space: pre-wrap;
                font-size: 12px;
              `}
            >
              {text}
            </div>
          )
        },
      },
      {
        title: () => {
          return <p css={columnTitleWhiteTextStyles}>EIA</p>
        },
        onHeaderCell: () => ({
          style: { background: colors.secondary, height: '20px' },
        }),
        children: [
          {
            title: () => {
              return <p css={columnTitleWhiteTextStyles}>EIA REPORT STATUS</p>
            },
            onHeaderCell: () => ({
              style: { background: '#006B93', height: '20px' },
            }),
            children: [
              {
                title: () => {
                  return <p css={columnTitleStyles}>1</p>
                },
                dataIndex: 'mddashboardStatusId',
                width: '5px',
                onCell: (record) => ({
                  rowSpan: record.dtOutRowSpan,
                  colSpan: renderColSpanEIAActiveSwitch(10, true, record.eiaactive),
                  style: {
                    backgroundColor: setColorReportStatus(record, 1),
                  },
                }),
                render: (_, record) => {
                  return record.eiaactive === 1 ? (
                    ''
                  ) : (
                    <div
                      css={css`
                        white-space: pre-wrap;
                        text-align: center;
                        font-size: 12px;
                        min-width: 300px;
                      `}
                    >
                      {record.eiaactiveReason}
                    </div>
                  )
                },
                onHeaderCell: () => ({
                  className: SPAN_HEADER_CLASS,
                }),
              },
              {
                title: () => {
                  return <p css={columnTitleStyles}>2</p>
                },
                dataIndex: 'mddashboardStatusId',
                width: '5px',
                onCell: (record) => ({
                  rowSpan: record.dtOutRowSpan,
                  colSpan: renderColSpanEIAActiveSwitch(0, false, record.eiaactive),
                  style: {
                    backgroundColor: setColorReportStatus(record, 2),
                  },
                }),
                render: (_: number) => {
                  return ''
                },
                onHeaderCell: () => ({
                  className: SPAN_HEADER_CLASS,
                }),
              },
              {
                title: () => {
                  return <p css={columnTitleStyles}>3</p>
                },
                dataIndex: 'mddashboardStatusId',
                width: '5px',
                onCell: (record) => ({
                  rowSpan: record.dtOutRowSpan,
                  colSpan: renderColSpanEIAActiveSwitch(0, false, record.eiaactive),
                  style: {
                    backgroundColor: setColorReportStatus(record, 3),
                  },
                }),
                render: (_: number) => {
                  return ''
                },
                onHeaderCell: () => ({
                  className: SPAN_HEADER_CLASS,
                }),
              },
              {
                title: () => {
                  return <p css={columnTitleStyles}>4</p>
                },
                dataIndex: 'mddashboardStatusId',
                width: '5px',
                onCell: (record) => ({
                  rowSpan: record.dtOutRowSpan,
                  colSpan: renderColSpanEIAActiveSwitch(0, false, record.eiaactive),
                  style: {
                    backgroundColor: setColorReportStatus(record, 4),
                  },
                }),
                render: (_: number) => {
                  return ''
                },
                onHeaderCell: () => ({
                  className: SPAN_HEADER_CLASS,
                }),
              },
              {
                title: () => {
                  return <p css={columnTitleStyles}>5</p>
                },
                dataIndex: 'mddashboardStatusId',
                width: '5px',
                onCell: (record) => ({
                  rowSpan: record.dtOutRowSpan,
                  colSpan: renderColSpanEIAActiveSwitch(0, false, record.eiaactive),
                  style: {
                    backgroundColor: setColorReportStatus(record, 5),
                  },
                }),
                render: (_: number) => {
                  return ''
                },
                onHeaderCell: () => ({
                  className: SPAN_HEADER_CLASS,
                }),
              },
              {
                title: () => {
                  return <p css={columnTitleStyles}>6</p>
                },
                dataIndex: 'mddashboardStatusId',
                width: '5px',
                onCell: (record) => ({
                  rowSpan: record.dtOutRowSpan,
                  colSpan: renderColSpanEIAActiveSwitch(0, false, record.eiaactive),
                  style: {
                    backgroundColor: setColorReportStatus(record, 6),
                  },
                }),
                render: (_: number) => {
                  return ''
                },
                onHeaderCell: () => ({
                  className: SPAN_HEADER_CLASS,
                }),
              },
              {
                title: () => {
                  return <p css={columnTitleStyles}>7</p>
                },
                dataIndex: 'mddashboardStatusId',
                width: '5px',
                onCell: (record) => ({
                  rowSpan: record.dtOutRowSpan,
                  colSpan: renderColSpanEIAActiveSwitch(0, false, record.eiaactive),
                  style: {
                    backgroundColor: setColorReportStatus(record, 7),
                  },
                }),
                render: (_: number) => {
                  return ''
                },
                onHeaderCell: () => ({
                  className: SPAN_HEADER_CLASS,
                }),
              },
            ],
          },
          {
            title: () => {
              return <p css={columnTitleWhiteTextStyles}>PROGRESS(%)</p>
            },
            onHeaderCell: () => ({
              style: { background: colors.primary, height: 26 },
            }),
            children: [
              {
                title: () => {
                  return <p css={columnTitleStyles}>PLAN</p>
                },
                dataIndex: 'progressPlan',
                width: '15px',
                onCell: (record) => ({
                  rowSpan: record.dtOutRowSpan,
                  colSpan: renderColSpanEIAActiveSwitch(0, false, record.eiaactive),
                }),
                render: (value) => {
                  return <p css={columnTextStyles}>{value}</p>
                },
                onHeaderCell: () => ({
                  className: SPAN_HEADER_CLASS,
                }),
              },
              {
                title: () => {
                  return <p css={columnTitleStyles}>ACTUAL</p>
                },
                dataIndex: 'progressActual',
                width: '15px',
                onCell: (record) => ({
                  rowSpan: record.dtOutRowSpan,
                  colSpan: renderColSpanEIAActiveSwitch(0, false, record.eiaactive),
                  style: { backgroundColor: record.planActualColors !== '' ? record.planActualColors : 'red' },
                }),
                render: (value, record) => (
                  <p
                    css={css`
                      color: ${record.planActualColors !== '' ? '#393a2c' : ''};
                      text-align: center;
                      font-size: 12px;
                    `}
                  >
                    {value}
                  </p>
                ),
                onHeaderCell: () => ({
                  className: SPAN_HEADER_CLASS,
                }),
              },
            ],
          },
          {
            title: () => {
              return <FixedWidthTitle width="200px" title="EIA HIGHLIGHT ACTIVITIES" />
            },
            render: (_, record) => (
              <CellEiaHighlightActivities highlightActivity={record.highlightActivity} wayForward={record.wayForward} />
            ),
            onCell: (record) => ({
              rowSpan: record.dtOutRowSpan,
              colSpan: renderColSpanEIAActiveSwitch(0, false, record.eiaactive),
            }),
            onHeaderCell: () => ({
              className: SPAN_HEADER_CLASS,
            }),
          },
        ],
      },
      {
        title: () => {
          return <p css={columnTitleWhiteTextStyles}>ENVIRONMENT COMPLIANCE AND MONITORING</p>
        },
        onHeaderCell: () => ({
          style: { background: '#002F41', height: 15 },
        }),
        children: [
          {
            title: () => {
              return <p css={columnTitleStyles}>EIA COMPLIANCE STATUS (DURING CONSTRUCTION)</p>
            },
            width: 50,
            children: [
              {
                title: () => {
                  return <p css={columnTitleWhiteTextStyles}>SHE PERFORMANCE</p>
                },
                onHeaderCell: () => ({
                  style: { background: '#2EBCA2', height: 15 },
                }),
                children: [
                  {
                    title: () => {
                      return <p css={columnTitleStyles}>PROJECT</p>
                    },
                    dataIndex: 'phase',
                    width: 15,
                    onCell: (record) => {
                      return {
                        rowSpan: record.monitorActive !== 1 ? record.dtOutRowSpan : 1,
                        colSpan: record.monitorActive === 0 ? 9 : 1,
                      }
                    },

                    render: (_, record) => {
                      return record.monitorActive === 1 ? (
                        <p
                          css={css`
                            text-align: center;
                            font-size: 12px;
                            font-weight: ${renderFontBold(record)};
                            color: ${renderColorBlack(record)};
                          `}
                        >
                          {record.phase} {`\n`} {record.phaseNote}
                        </p>
                      ) : (
                        <p
                          css={css`
                            text-align: center;
                            white-space: pre-wrap;
                            font-size: 12px;
                            font-weight: ${renderFontBold(record)};
                            color: ${renderColorBlack(record)};
                          `}
                        >
                          {record.monitorReason}
                        </p>
                      )
                    },
                    onHeaderCell: () => ({
                      className: SPAN_HEADER_CLASS,
                    }),
                  },
                  {
                    title: () => {
                      return <p css={columnTitleStyles}>{`MAN-HOUR ${currentYear}`}</p>
                    },
                    dataIndex: 'curentYearMan',
                    width: 15,
                    onCell: (record) => ({
                      colSpan: renderColSpanMonitoringActive(record.monitorActive),
                    }),
                    render: (value: number, record) => {
                      return (
                        <p
                          css={css`
                            text-align: center;
                            font-size: 12px;
                            font-weight: ${renderFontBold(record)};
                            color: ${renderColorBlack(record)};
                          `}
                        >
                          {numberFormat(value)}
                        </p>
                      )
                    },
                    onHeaderCell: () => ({
                      className: SPAN_HEADER_CLASS,
                    }),
                  },
                  {
                    title: () => {
                      return <p css={columnTitleStyles}>TOTAL MAN-HOUR</p>
                    },
                    dataIndex: 'totalMan',
                    width: 15,
                    onCell: (record) => ({
                      colSpan: renderColSpanMonitoringActive(record.monitorActive),
                    }),
                    render: (value, record) => {
                      return (
                        <p
                          css={css`
                            text-align: center;
                            font-size: 12px;
                            font-weight: ${renderFontBold(record)};
                            color: ${renderColorBlack(record)};
                          `}
                        >
                          {numberFormat(value)}
                        </p>
                      )
                    },
                    onHeaderCell: () => ({
                      className: SPAN_HEADER_CLASS,
                    }),
                  },
                  {
                    title: () => {
                      return <p css={columnTitleStyles}>TARGET MAN-HOUR</p>
                    },
                    dataIndex: 'targetMan',
                    width: 15,
                    onCell: (record) => ({
                      colSpan: renderColSpanMonitoringActive(record.monitorActive),
                    }),
                    render: (value, record) => {
                      return (
                        <p
                          css={css`
                            text-align: center;
                            font-size: 12px;
                            font-weight: ${renderFontBold(record)};
                            color: ${renderColorBlack(record)};
                          `}
                        >
                          {numberFormat(value)}
                        </p>
                      )
                    },
                    onHeaderCell: () => ({
                      className: SPAN_HEADER_CLASS,
                    }),
                  },
                  {
                    title: () => {
                      return <p css={columnTitleStyles}>{`LTA=${ltaTitleValue}`}</p>
                    },
                    dataIndex: 'lta',
                    width: 15,

                    onCell: (record) => ({
                      colSpan: renderColSpanMonitoringActiveWithoutLTA(record, 2),
                    }),

                    render: (text) => {
                      return <p css={columnTextStyles}>{text}</p>
                    },
                    onHeaderCell: () => ({
                      className: SPAN_HEADER_CLASS,
                    }),
                  },
                  {
                    title: () => {
                      return <p css={columnTitleStyles}>{`TRIR<${trirTitleValue}`}</p>
                    },
                    dataIndex: 'trir',
                    width: 15,
                    onCell: (record) => ({
                      colSpan: renderColSpanMonitoringActiveWithoutLTA(record, 0),
                    }),
                    render: (value) => {
                      return <p css={columnTextStyles}>{value}</p>
                    },
                    onHeaderCell: () => ({
                      className: SPAN_HEADER_CLASS,
                    }),
                  },
                ],
              },
              {
                title: () => {
                  return <p css={columnTitleWhiteTextStyles}>EIA PERFORMANCE</p>
                },
                onHeaderCell: () => ({
                  style: { background: '#00338D', height: 15 },
                }),
                children: [
                  {
                    title: () => {
                      return <p css={columnTitleStyles}>EIA COMPLIANCE</p>
                    },
                    dataIndex: 'eiaCompliance',
                    width: 40,
                    render: (_, record) =>
                      record?.eiaCompliance === '0' ? (
                        <span
                          css={css`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 60%;
                          `}
                        >
                          <CheckIcon alt="check" />
                        </span>
                      ) : (
                        <span
                          css={css`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 60%;
                          `}
                        >
                          <CloseIcon alt="close" />
                        </span>
                      ),
                    onCell: (record) => ({
                      rowSpan: record.dtOutRowSpan,
                      colSpan: renderColSpanMonitoringActive(record.monitorActive),
                    }),
                    onHeaderCell: () => ({
                      className: SPAN_HEADER_CLASS,
                    }),
                  },
                  {
                    title: () => {
                      return <FixedWidthTitle width="200px" title="SIX-MONTH REPORT SUBMISSION" />
                    },
                    dataIndex: 'sixMonth',
                    width: 40,
                    render: (text) => {
                      return (
                        <div
                          css={css`
                            white-space: pre-wrap;
                            font-size: 12px;
                          `}
                        >
                          {text}
                        </div>
                      )
                    },
                    onCell: (record) => ({
                      rowSpan: record.dtOutRowSpan,
                      colSpan: renderColSpanMonitoringActive(record.monitorActive),
                    }),
                    onHeaderCell: () => ({
                      className: SPAN_HEADER_CLASS,
                    }),
                  },
                ],
              },
              {
                title: <FixedWidthTitle width="300px" title="EIA COMPLIANCE HIGHLIGHT ACTIVITIES" />,
                dataIndex: 'complianceHighlight',
                render: (text) => {
                  return (
                    <div
                      css={css`
                        white-space: pre-wrap;
                        font-size: 12px;
                        min-width: 300px;
                      `}
                    >
                      {text}
                    </div>
                  )
                },
                onCell: (record) => ({
                  rowSpan: record.dtOutRowSpan,
                  colSpan: renderColSpanMonitoringActive(record.monitorActive),
                }),
                onHeaderCell: () => ({
                  className: SPAN_HEADER_CLASS,
                }),
              },
            ],
          },
        ],
      },
    ],
    [ltaTitleValue, trirTitleValue, currentYear, renderFontBold, renderColorBlack, setColorReportStatus],
  )
  return (
    <Table
      css={css`
        .ant-table-tbody {
          tr:nth-of-type(even) {
            background-color: white;
            .ant-table-cell-fix-right {
              background-color: white;
            }
          }
        }
      `}
      rowKey="key"
      columns={columns}
      pagination={false}
      bordered
      dataSource={preparedDataSource}
    />
  )
}
